export const Types = {
  LOAD_MATCHES_REQUEST: '@matches/LOAD_MATCHES_REQUEST',
  LOAD_MATCHES_SUCCESS: '@matches/LOAD_MATCHES_SUCCESS',
  LOAD_MATCHES_FAILED: '@matches/LOAD_MATCHES_FAILED',

  LOAD_MESSAGES_REQUEST: '@messages/LOAD_MESSAGES_REQUEST',
  LOAD_MESSAGES_SUCCESS: '@messages/LOAD_MESSAGES_SUCCESS',
  LOAD_MESSAGES_FAILED: '@messages/LOAD_MESSAGES_FAILED',

  SEND_MESSAGE_REQUEST: '@messages/SEND_MESSAGE_REQUEST',
  SEND_MESSAGE_SUCCESS: '@messages/SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILED: '@messages/SEND_MESSAGE_FAILED',

  RECEIVE_MESSAGE: '@messages/RECEIVE_MESSAGE',
  SWITCH_CURRENT_CHAT: '@messages/SWITCH_CURRENT_CHAT',

  DELETE_MATCH_REQUEST: '@matches/DELETE_MATCH_REQUEST',
  DELETE_MATCH_SUCCESS: '@matches/DELETE_MATCH_SUCCESS',
  DELETE_MATCH_FAILED: '@matches/DELETE_MATCH_FAILED',

  UPDATE_UNREAD_MATCHS: '@matches/UPDATE_UNREAD_MATCHS',

  LOAD_MORE_MESSAGES_REQUEST: '@messages/LOAD_MORE_MESSAGES_REQUEST',
  LOAD_MORE_MESSAGES_SUCCESS: '@messages/LOAD_MORE_MESSAGES_SUCCESS',
  LOAD_MORE_MESSAGES_FAILED: '@messages/LOAD_MORE_MESSAGES_FAILED',
}

export const Actions = {
  
  loadMatchesRequest: () => ({
    type: Types.LOAD_MATCHES_REQUEST,
  }),
  
  loadMatchesSuccess: (data) => ({
    type: Types.LOAD_MATCHES_SUCCESS,
    payload: { data }
  }),
  
  loadMatchesFailed: (err) => ({
    type: Types.LOAD_MATCHES_FAILED,
    payload: { err }
  }),


  
  loadMessagesRequest: (matchId, page) => ({
    type: Types.LOAD_MESSAGES_REQUEST,
    payload: { matchId, page }
  }),
  
  loadMessagesSuccess: (messages) => ({
    type: Types.LOAD_MESSAGES_SUCCESS,
    payload: { messages }
  }),
  
  loadMessagesFailed: (err) => ({
    type: Types.LOAD_MESSAGES_FAILED,
    payload: { err }
  }),



  sendMessageRequest: (matchId, content) => ({
    type: Types.SEND_MESSAGE_REQUEST,
    payload: { matchId, content }
  }),
  
  sendMessageSuccess: (matchId, content) => ({
    type: Types.SEND_MESSAGE_SUCCESS,
    payload: { matchId, content }
  }),
  
  sendMessageFailed: (err) => ({
    type: Types.SEND_MESSAGE_FAILED,
    payload: { err }
  }),

  
  receiveMessage: (data) => ({
    type: Types.RECEIVE_MESSAGE,
    payload: { data }
  }),

  switchCurrentChat: (roomId) => ({
    type: Types.SWITCH_CURRENT_CHAT,
    payload: { roomId }
  }),



  deleteMatchRequest: (matchId) => ({
    type: Types.DELETE_MATCH_REQUEST,
    payload: { matchId }
  }),

  deleteMatchSuccess: () => ({
    type: Types.DELETE_MATCH_SUCCESS
  }),

  deleteMatchFailed: (err) => ({
    type: Types.DELETE_MATCH_FAILED,
    payload: { err }
  }),

  updateUnreadMessages: (roomId, unread) => ({
    type: Types.UPDATE_UNREAD_MATCHS,
    payload: { roomId, unread }
  }),



  loadMoreMessagesRequest: (matchId, page) => ({
    type: Types.LOAD_MORE_MESSAGES_REQUEST,
    payload: { matchId, page }
  }),

  loadMoreMessagesSuccess: (messages, page) => ({
    type: Types.LOAD_MORE_MESSAGES_SUCCESS,
    payload: { messages, page }
  }),

  loadMoreMessagesFailed: (err) => ({
    type: Types.LOAD_MORE_MESSAGES_FAILED,
    payload: { err }
  })

}


