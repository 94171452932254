import React from "react";
import Route from "./Route";

import { Switch } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/Login";
import LoginMinisterio from "../pages/LoginMinisterio";
import LoginSolteiro from "../pages/LoginSolteiro";
import ChooseMusic from "../pages/ChooseMusic";
import CreateAccount from "../pages/CreateAccount";
import Tutorial from "../pages/Tutorial";
import Profile from "../pages/Profile";
import ProfileEdit from "../pages/Profile/Edit";
import Match from "../pages/Match";
import Chat from "../pages/Chat";
import Page404 from "../pages/Page404";
import EndCampaign from "../pages/EndCampaign";

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/" component={() => <Page404 />} />
      <Route
        exact
        path="/gusttavo"
        component={() => <LoginSolteiro />}
        isLogin
      />
      <Route
        exact
        path="/playlist"
        component={() => <ChooseMusic />}
        isPrivate
      />
      <Route
        exact
        path="/profile"
        component={() => <Profile teste='teste' />}
        isPrivate
      />

      <Route
        exact
        path="/profile/edit"
        component={() => <ProfileEdit teste='teste' />}
        isPrivate
      />
      
      <Route exact path="/tutorial" component={() => <Tutorial teste='teste' />} isPrivate />
      <Route exact path="/signin" component={() => <CreateAccount teste={true} />} isPrivate />


      <Route exact path="/match" component={() => <Match teste={true}  />} isPrivate />

      <Route exact path="/nomatch" component={() => <Match teste={true} nomatch={true} />} isPrivate />

      <Route exact path="/havematch" component={() => <Match teste={true} match={true} />} isPrivate />



      <Route exact path="/chat" component={() => <Chat teste={true} />} isPrivate />

      {window.location.pathname.includes("ministeriodonamoro") ? (
        <Route
          exact
          path="/:campaign_login"
          component={() => <LoginMinisterio />}
          isLogin
        />
      ) : (
        <Route
          exact
          path="/:campaign_login"
          component={() => <Login />}
          isLogin
        />
      )}
      <Route
        exact
        path="/app/signin"
        component={() => <CreateAccount />}
        isPrivate
      />
      <Route exact path="/app/home" component={() => <Home />} isPrivate />
      <Route
        exact
        path="/app/tutorial"
        component={() => <Tutorial />}
        isPrivate
      />
      <Route
        exact
        path="/app/profile"
        component={() => <Profile />}
        isPrivate
      />
      <Route
        exact
        path="/app/profile/edit"
        component={() => <ProfileEdit />}
        isPrivate
      />
      <Route exact path="/app/match" component={() => <Match />} isPrivate />
      <Route exact path="/app/chat" component={() => <Chat />} isPrivate />
      <Route exact path="/app/404" component={() => <Page404 />} notFound />
      <Route
        exact
        path="/app/end"
        component={() => <EndCampaign />}
        endCampaign
      />
    </Switch>
  );
}
