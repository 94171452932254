import React, {useState} from 'react';
import {Page, Container, Button, Grid, Navbar, Divider, Image, Modal, Wrapper} from '../../components';

import {Link} from 'react-router-dom'
import { useSelector } from 'react-redux';
import defaultImage from "../../assets/images/default.jpg";

function Front({userData, ...props}) {
  const [modalShow, setModalShow] = useState(false)
  const [pageLoading, setPageLoading] = useState(false)
  const [modalLoading, setModalLoading] = useState(false)
  const { currentCampaign } = useSelector(state => state.auth)

  return (
    props.teste && props.teste === 'teste' ? 
      <Page name="profile" loading={pageLoading} className='campaign-gusttavo-profile' >
        <Navbar chatCount={5} menuHighlighter="perfil" teste='teste'/>
        <Container center>
          <Wrapper className='campaign-gusttavo-profile-wrapper'>
              {/* <Image className="circled mt-4" src={userData.image} /> */}
              <Image className="circled mt-4" src={defaultImage} />
              {/* <h2 className="h5 text-center mb-0 mt-2">{userData.name}</h2> */}
              <h2 className="h5 text-center mb-0 mt-2">Teste teste</h2>
              {/* <p className="text-center mb-0 mt-1">{userData.email}</p> */}
              <p className="text-center mb-0 mt-1">teste@teste.com</p>
              {/* <p className="text-center mb-4 mt-1">{userData.address} • {userData.age} anos</p> */}
              <p className="text-center mb-4 mt-1">Teste - TS • 18 anos</p>
              <Button className="full flex primary-gusttavo mb-2" link={Link} to="/app/profile/edit">Editar perfil</Button>
              <Button onClick={() => {setPageLoading(true); props.onLogout()}} className="full m-0">Sair</Button>
              <Divider className="my-3" />
              <Button className="full" onClick={() => setModalShow('delete')}>Deletar conta</Button>
              <div className="flex-space-around mt-5">
                <Link to="/app/tutorial">Como funciona?</Link>
                <a href={props.faqDownloadUrl} target="_blank" rel="noreferrer">FAQ</a>
              </div>
          </Wrapper>
        </Container>

        <Modal show={modalShow}>
          <Modal.content
            show={modalShow == 'delete'}
            disabled={modalLoading}
            className="size-2"
            onClose={() => setModalShow(false)}
            header={<b>Deletar conta</b>}
            footer={
              <div>
                <Button className="width-full" loading={modalLoading} onClick={() => {setModalLoading(true); props.onDeleteAccount();}}>Deletar conta</Button>
                <Button className="width-full primary mt-1" disabled={modalLoading} onClick={() => setModalShow(false)}>Cancelar</Button>
              </div>}
          >
            <p className="text-center"><b>Tem certeza que deseja deletar sua conta?</b></p>
            <p className="text-center">Todas as suas informações serão excluídas e essa ação não poderá ser desfeita.</p>
          </Modal.content>

          <Modal.content
            show={modalShow == 'faq'}
            disabled={modalLoading}
            onClose={() => setModalShow(false)}
            header={<div>Perguntas frequentes</div>}
          >

            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </Modal.content>
        </Modal>
      </Page> 
    : 
      <Page name="profile" loading={pageLoading} >
        <Navbar chatCount={5} menuHighlighter="perfil"  />
        <Container center>
          <Wrapper>
              <Image className="circled mt-4" src={userData.image} />
              <h2 className="h5 text-center mb-0 mt-2">{userData.name}</h2>
              <p className="text-center mb-0 mt-1">{userData.email}</p>
              <p className="text-center mb-4 mt-1">{userData.address} • {userData.age} anos</p>
              <Button className="full flex primary mb-2" link={Link} to="/app/profile/edit">Editar perfil</Button>
              <Button onClick={() => {setPageLoading(true); props.onLogout()}} className="full m-0">Sair</Button>
              <Divider className="my-3" />
              <Button className="full" onClick={() => setModalShow('delete')}>Deletar conta</Button>
              <div className="flex-space-around mt-5">
                <Link to="/app/tutorial">Como funciona?</Link>
                <a href={props.faqDownloadUrl} target="_blank" rel="noreferrer">FAQ</a>
              </div>
          </Wrapper>
        </Container>

        <Modal show={modalShow}>
          <Modal.content
            show={modalShow == 'delete'}
            disabled={modalLoading}
            className="size-2"
            onClose={() => setModalShow(false)}
            header={<b>Deletar conta</b>}
            footer={
              <div>
                <Button className="width-full" loading={modalLoading} onClick={() => {setModalLoading(true); props.onDeleteAccount();}}>Deletar conta</Button>
                <Button className="width-full primary mt-1" disabled={modalLoading} onClick={() => setModalShow(false)}>Cancelar</Button>
              </div>}
          >
            <p className="text-center"><b>Tem certeza que deseja deletar sua conta?</b></p>
            <p className="text-center">Todas as suas informações serão excluídas e essa ação não poderá ser desfeita.</p>
          </Modal.content>

          <Modal.content
            show={modalShow == 'faq'}
            disabled={modalLoading}
            onClose={() => setModalShow(false)}
            header={<div>Perguntas frequentes</div>}
          >

            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
            <p><b>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has?</b></p>
            <p className="mb-3">Standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
          </Modal.content>
        </Modal>
      </Page> 
)
}

export default Front;
