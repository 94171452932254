// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./TTNorms-Medium.woff2";
import ___CSS_LOADER_URL_IMPORT_1___ from "./TTNorms-Bold.woff2";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n  font-family: \"TT Norms\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\");\n  font-weight: 500;\n  font-style: normal;\n  font-display: swap;\n}\n@font-face {\n  font-family: \"TT Norms\";\n  src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff2\");\n  font-weight: bold;\n  font-style: normal;\n  font-display: swap;\n}", "",{"version":3,"sources":["webpack://src/assets/fonts/stylesheet.css"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,4DAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;AACJ;AAEA;EACI,uBAAA;EACA,4DAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AAAJ","sourcesContent":["@font-face {\n    font-family: 'TT Norms';\n    src: url('TTNorms-Medium.woff2') format('woff2');\n    font-weight: 500;\n    font-style: normal;\n    font-display: swap;\n}\n\n@font-face {\n    font-family: 'TT Norms';\n    src: url('TTNorms-Bold.woff2') format('woff2');\n    font-weight: bold;\n    font-style: normal;\n    font-display: swap;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
