import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Actions as StatesActions } from '../../store/modules/states/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

import Front from './front';

const Index = (props) => {
  console.log(props)
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if(!props.teste) {
      const included = 'included=city,city.state';
      dispatch(ProfileActions.loadProfileRequest(included));
      dispatch(StatesActions.loadStatesRequest());
    }
  },[]);


  const profileReducer = useSelector(state => state.profile)
  const statesReducer = useSelector(state => state.statesbr)

  const authReducer = useSelector(state => state.auth)
  const campaignReducer = useSelector(state => state.campaigns)

  useEffect(() => {
    if(!props.teste) {
      const { currentCampaign } = campaignReducer
      const campaignSlug = authReducer.currentCampaign

      if(currentCampaign) return

      dispatch(CampaignActions.loadCurrerntCampaignRequest(campaignSlug))
    }
  },[]);

  const [disableCities, setDisableCities] = useState(true);
  const [formData, formSetData] = useState({
    city: "",
    email: "",
    genre: "",
    date: "",
    genreFemale: false,
    genreMale: false,
    genreOther: false,
    genreNotBinary: false,
    name: "",
    description: "",
    state: ""
  });

  useEffect(() => {
    if(!props.teste) {

      formSetData({
        ...formData,
        image: profileReducer.image,
        name: profileReducer.name,
        email: profileReducer.email,
        date: profileReducer.birthdate,
        description: profileReducer.description,
        genre: normalizeGenre(profileReducer.genre),
        genreFemale: profileReducer.preferences.some(item => item === 'female'),
        genreMale: profileReducer.preferences.some(item => item === 'male'),
        genreOther: profileReducer.preferences.some(item => item === 'undefined'),
        genreNotBinary: profileReducer.preferences.some(item => item === 'non_binary'),
        city: profileReducer.currentCityId,
        state: profileReducer.currentStateAcronym,
        loadEnd: profileReducer.loadEnd,
      });
    }
  }, [profileReducer]);

  const normalizeGenre = (value) => {
    if(value === 'female') return 'm'
    if(value === 'male') return 'h'
    if(value === 'non_binary') return 'nb'
    if(value === 'undefined') return 'o'
  }

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  const submit = async frontData => {
    const { name, email, date, genre, city, description, image } = frontData

    let preferences = []
    if(frontData.genreFemale) preferences.push('female')
    if(frontData.genreMale) preferences.push('male')
    if(frontData.genreNotBinary) preferences.push('non_binary')
    if(frontData.genreOther) preferences.push('undefined')

    let normalizedGenre;
    if(genre === 'm') normalizedGenre = 'female'
    if(genre === 'h') normalizedGenre = 'male'
    if(genre === 'o') normalizedGenre = 'undefined'
    if(genre === 'nb') normalizedGenre = 'non_binary'

    dispatch(ProfileActions.updateProfileRequest(
      image,
      name,
      email,
      date,
      normalizedGenre,
      preferences,
      description,
      city
    ))
  }

  const onChangeState = (acronym) => {
    if(!props.teste) {

      if(acronym === '- Selecionar -') {
        setDisableCities(true)
        return
      }
      const stateSelected = statesReducer.states.find(item => item.attributes.acronym === acronym)
      dispatch(StatesActions.loadCitiesRequest(stateSelected.id))
      setDisableCities(false)
    }
  }

  const normalizeStates = () => {
    if(!props.teste) {

      const normalized = statesReducer.states.map(item => {
        return { value: item.attributes.acronym, label: item.attributes.name }
      });
      return [{ value: null, label: '- Selecionar -' }, ...normalized]
    }
  }

  const normalizeCities = () => {
    if(!props.teste) {
    
      const normalized = statesReducer.cities.map(item => {
        return { value: item.id, label: item.attributes.name }
      });
      return [{ value: null, label: '- Selecionar -' }, ...normalized]
    }
  }

  useEffect(() => {
    if(!props.teste) {
      if(!statesReducer.states.length) return;

      const stateSelected = statesReducer.states.find(
        item => item.attributes.acronym === profileReducer.currentStateAcronym
      )

      if(!stateSelected) return;

      dispatch(StatesActions.loadCitiesRequest(stateSelected.id))
    }
  }, [profileReducer.currentStateAcronym, statesReducer.states]);

  return (
    <Fragment>
      {props.teste ? 
          <Front
            loadingForm={''}
            formData={''}
            formSetData={''}

            // onSaveClick={frontData => console.log(frontData)}
            onSaveClick={''}

            statesOptions={''}
            onChangeState={''}

            citiesOptions={''}
            disableCities={''}
            genreOptions={[
              {value:'-',label:'- Selecionar -'},
              {value:'m',label:'Mulher'},
              {value:'h',label:'Homem'},
              {value:'nb',label:'Não-binário'},
              {value:'o',label:'Outro'},
            ]}

            coverImagesSrc={{}}
            teste={props.teste}
          />
      :
      formData?.loadEnd &&
        <Front
          loadingForm={profileReducer.updatingProfile}
          formData={formData}
          formSetData={formSetData}

          // onSaveClick={frontData => console.log(frontData)}
          onSaveClick={frontData => submit(frontData)}

          statesOptions={normalizeStates()}
          onChangeState={onChangeState}

          citiesOptions={normalizeCities()}
          disableCities={disableCities}
          genreOptions={[
            {value:'-',label:'- Selecionar -'},
            {value:'m',label:'Mulher'},
            {value:'h',label:'Homem'},
            {value:'nb',label:'Não-binário'},
            {value:'o',label:'Outro'},
          ]}

          coverImagesSrc={{
            mobile: campaignReducer.currentCampaign?.attributes?.image_mobile,
            desktop: campaignReducer.currentCampaign?.attributes?.image_desktop,
          }}
        />}
    </Fragment>
  )
}

export default Index;
