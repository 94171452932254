import { select, all, call, takeLatest, put } from 'redux-saga/effects';
import { Matches } from '../../../services/api/matches';

import { Types, Actions } from './actions';

import Jsona from 'jsona';
const dataFormatter = new Jsona();

function* loadMatches() {

  try {
    const response = yield call(Matches.load);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadMatchesSuccess(data))
  } catch(err) {
    console.log(err)
    yield put(Actions.loadMatchesFailed(err))
  }
}

function* loadMessages({ payload }) {
  const { matchId, page } = payload

  try {
    const response = yield call(Matches.loadMessages, matchId, page);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadMessagesSuccess(data))
  } catch(err) {
    yield put(Actions.loadMessagesFailed(err))
  }
}


function* loadMoreMessages({ payload }) {
  const { matchId, page } = payload
  console.log('page >>>')
  console.log(page)

  try {
    const response = yield call(Matches.loadMessages, matchId, page);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadMoreMessagesSuccess(data, page))
  } catch(err) {
    yield put(Actions.loadMoreMessagesFailed(err))
  }
}


function* sendMessage({ payload }) {
  const { matchId, content } = payload

  try {
    yield call(Matches.sendMessage, matchId, { message: { content } });

    yield put(Actions.sendMessageSuccess(matchId, content))
  } catch(err) {
    yield put(Actions.sendMessageFailed(err))
  }
}

function* deleteMatch({ payload }) {
  const { matchId } = payload

  try {
    yield call(Matches.delete, matchId);

    yield put(Actions.deleteMatchSuccess())
  } catch(err) {
    yield put(Actions.deleteMatchFailed(err))
  }

  yield loadMatches();
}

export default all([
  takeLatest(Types.LOAD_MATCHES_REQUEST, loadMatches),
  takeLatest(Types.LOAD_MESSAGES_REQUEST, loadMessages),
  takeLatest(Types.LOAD_MORE_MESSAGES_REQUEST, loadMoreMessages),
  takeLatest(Types.SEND_MESSAGE_REQUEST, sendMessage),
  takeLatest(Types.DELETE_MATCH_REQUEST, deleteMatch),
]);