import produce from 'immer';
import { Types } from './actions';
import { Types as ProfileTypes } from '../profile/actions';

const initialState = {
  profile: null,
  token: null,
  logged: false,
  currentCampaign: null,
  profileComplete: false,
}

export const auth = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.SET_PROFILE_INFOS: {
        draft.profile = action.payload.data
        break;
      }
      case Types.SET_AUTH_INFOS: {
        const { token, campaign } = action.payload

        draft.token = token;
        draft.currentCampaign = campaign;
        draft.logged = true;

        break;
      }
      case Types.LOGOUT: {
        draft.token = null;
        draft.currentCampaign = null;
        draft.logged = false;
        break;
      }
      case ProfileTypes.DELETE_ACCOUNT_SUCCESS: {
        draft.token = null;
        draft.currentCampaign = null;
        draft.logged = false;
        break;
      }
      case Types.SET_PROFILE_COMPLETE: {
        const { isComplete } = action.payload

        draft.profileComplete = isComplete
        break;
      }
      case Types.ACCESS_DENIED: {
        draft.token = null
        draft.logged = false
        draft.profileComplete = false
        
        break;
      }
      default:
    }
  });
}