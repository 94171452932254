import React from 'react';
import {Icon} from '../../components';


function Slider({children, ...props}) {

  return (
    <div className="Slider">
      {children}
    </div>
  );
}

export { Slider };
