import React from 'react';
import {Page, Divider, Container, Button} from '../components';

function Home(props) {

  return (
    <Page name="home">
      <Container center>
        <Button className="primary">Home Button 2</Button>
      </Container>
      <Divider>
        asdasd
      </Divider>
    </Page>
  );
}

export default Home;
