import React from 'react';
import {Image} from '../../components';

function LoginLayout({children, ...props}) {

  return (
    <div className="LoginLayout">
      <div className="layout-left">{children}</div>
      <div className="layout-right">
        <Image className="hide_md" src={props.coverImagesSrc.mobile} />
        <Image className="hide_md-max" src={props.coverImagesSrc.desktop} />
      </div>
    </div>
  );
}

export { LoginLayout };
