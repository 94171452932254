import React from 'react';
import {
  Page, Divider, Container, Button, Grid, Image, Logo,
  LoginLayout
} from '../../components';

function Front(props) {

  return (
    <Page 
      name="login"
      loading={props.loading}
    >
      <LoginLayout coverImagesSrc={props.coverImagesSrc}>
        <div className="page-login-card">
          {props.finishLoading &&
          <div className="page-login-card-inner">
            <Logo />
            <div className="page-login-card-bg mt-3">
              <h1 className="h4 text-center mt-0">{props.title}</h1>
              <p className="text-center">{props.subtitle}</p>
              <Button className="primary full" href={props.loginUrl}>Entrar com Spotify</Button>
            </div>
            <a className="block text-center mt-2 mt-5_lg" target="_blank" href={props.termsUrl}>Termos e condições</a>
          </div>}
        </div>
      </LoginLayout>
    </Page>
  );
}

export default Front;
