import produce from 'immer';
import { Types } from './actions';

const initialState = {
  currentCampaign: null,
  loading: false,
  finishLoading: false,
}

export const campaigns = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOAD_CURRENT_CAMPAIGN_REQUEST: {
        draft.loading = true
        break;
      }
      case Types.LOAD_CURRENT_CAMPAIGN_SUCCESS: {
        const { campaign } = action.payload
        draft.currentCampaign = campaign
        draft.loading = false
        draft.finishLoading = true
        break;
      }
      case Types.LOAD_CURRENT_CAMPAIGN_FAILED: {
        draft.loading = false
        draft.finishLoading = true
        break;
      }
      default:
    }
  });
}