import React, {useState, useEffect} from 'react';
import {Page, Divider, Container, Button, Wrapper, Image, Icon, AccountForm, ProfileCard, Tabs, Grid} from '../../../components';

import { useSelector } from 'react-redux';
import {Link} from 'react-router-dom'

import dateToAge from '../../../utils/dateToAge';
import { MusicList } from '../../../components/MusicList';

function Front(props) {
  const [currentTab, setCurrentTab] = useState(0)
  const [userData, setUserData] = useState(null)
  const [localData, setLocalData] = useState(props.formData)
  const [imageData, setImageData] = useState()
  const { currentCampaign } = useSelector(state => state.auth)

  useEffect(() => {
    console.log(props)
    if( props?.teste !== 'teste') {

      if (!localData) {
        return;
      }
      const selectedCity = props.citiesOptions.find(item => parseInt(item.value) == parseInt(localData.city))
      if (selectedCity) {
        setUserData({...userData, ...localData, age: dateToAge(localData.date), address: `${selectedCity.label}, ${localData.state}`});
      } else {
        setUserData({...userData, ...localData, age: dateToAge(localData.date), address:''});
      }
    }

  }, [localData, props.citiesOptions])

  return ( 
    props?.teste === 'teste' ? //no lugar desse if entro um if de currentCampaign === a campanha do Gusttavo
      <Page name="profile-edit-gusttavo" loading={props.loadingForm}>
        <div className="profile-edit-navbar">
          <Container center>
            <Button link={Link} to="/app/profile"><Icon name="arrow-left"/> <span>Voltar</span></Button> Editar perfil
          </Container>
        </div>
        <div className='content_profile_gusttavo'>
          <Container center>
            <Grid>
              <Grid.cell className="cell-6_lg profile-info">
                <Wrapper className="mt-lg">
                  <AccountForm
                    saveButtonLabel="Salvar"
                    data={localData}
                    setData={setLocalData}
                    imageData={imageData}
                    setImageData={setImageData}
                    statesOptions={props.statesOptions}
                    citiesOptions={props.citiesOptions}
                    genreOptions={props.genreOptions}
                    disableCities={props.disableCities}

                    onChangeState={props.onChangeState}
                    onSaveClick={() => props.onSaveClick({...localData, ...imageData})}
                    teste='teste'
                    notCreate={true}
                  />
                </Wrapper>
              </Grid.cell>

              <Grid.cell className="cell-6_lg">
                <MusicList title='Sua playlist' />
              </Grid.cell>
            </Grid>
            <div className="action-button">
              <Button className="save" onClick={() => props.onSaveClick()}> Salvar Alterações</Button>
            </div>
          </Container>
        </div>
      </Page> 
    :
      <Page name="profile-edit" loading={props.loadingForm}>
      <div className="profile-edit-navbar">
        <Container center>
          <Button link={Link} to="/app/profile"><Icon name="arrow-left"/> <span>Voltar</span></Button> Editar perfil
        </Container>
      </div>

      <Tabs onChange={index => setCurrentTab(index)}>
        <Tabs.tab>Editar</Tabs.tab>
        <Tabs.tab>Previsualização</Tabs.tab>
      </Tabs>

      {localData &&
        <Container center>
          {currentTab == 0 &&
            <Wrapper className="mt-lg">
              <AccountForm
                saveButtonLabel="Salvar alterações"
                data={localData}
                setData={setLocalData}
                imageData={imageData}
                setImageData={setImageData}
                statesOptions={props.statesOptions}
                citiesOptions={props.citiesOptions}
                genreOptions={props.genreOptions}
                disableCities={props.disableCities}

                onChangeState={props.onChangeState}
                onSaveClick={() => props.onSaveClick({...localData, ...imageData})}

              />
            </Wrapper>
          }
          {currentTab == 1 &&
            <Grid>
              <Grid.cell className="cell-start1 cell-end-12 cell-start-2_lg cell-end-11_lg cell-start-3_md cell-end-10_md">
                <ProfileCard userData={userData} />
              </Grid.cell>
            </Grid>
          }
        </Container>
      }
      </Page>
  );
}

export default Front;
