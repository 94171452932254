import React, {useState, useRef, useEffect} from 'react';
import {Page, Container, Grid, Button, ProfileCard, Navbar, Divider, Image, Badge, Modal, Field, Icon, Wrapper, utils} from '../../components';

import {Link} from 'react-router-dom'

function Front(props) {
  
  const sendMessage = () => {
    props.onSendMessage(textareaRef.current.value);
    textareaRef.current.value = "";
  }

  const [showMessage, setShowMessage] = useState();
  const [modalShow, setModalShow] = useState('');
  const [modalLoading, setModalLoading] = useState(false);

  const textareaRef = useRef();
  const talkMessages = useRef();
  const talkNav = useRef();

  const onEnterPress = (e) => {
    if(e.keyCode == 13 && e.shiftKey == false) {
      e.preventDefault();
      sendMessage();
    }
  }

  const setTalkHeight = () => {
    utils.timeout(() => {
      if (talkMessages.current) {
        const e  = talkMessages.current;
        const rect = e.getBoundingClientRect();
        e.style.height = (window.innerHeight - rect.top - 80)+"px";
      }
    },100)
  }

useEffect(() => {
  if(!props.teste) {
    setTalkHeight();
  }
},[])

useEffect(() => {
  if(!props.teste) {

    if (props.chatList.length && props.currentChat && props.currentChat.newMessage) {
      talkMessages.current.scrollTop = talkMessages.current.scrollHeight - talkMessages.current.clientHeight;
    }
  }
}, [props.currentChat.chat])

useEffect(() => {
  if(!props.teste) {

    if (props.deleteMatchOk) {
      setModalShow(false)
    }
  }
}, [props.deleteMatchOk])

  return ( props.teste ?
    <Page name="chat-gusttavo" className={showMessage?'is-show-message':''} loading={props.loadingForm}>
      <Navbar chatCount={5} menuHighlighter="chat" teste='teste'/>
      <Container center>
        {!props.chatList.length &&
          <Wrapper className="text-center mt-6">
            <b>Quer iniciar uma conversa?</b>
            <p>Quando você e outra pessoa dão um Match, você pode enviar mensagens para elas.</p>
            <Button className="primary full find-match" onClick={() => props.onGotoMatch()}>Encontrar Match</Button>
          </Wrapper>
        }

        {props.chatList.length != 0 &&
          <Grid>
            <Grid.cell className="chat-list cell-start-1_md cell-end-5_md">
              {props.chatList.map((item, index) => {
                return (
                  <div className={`chat-list-item ${item.id === props.currentChat.id ? 'active' : ''}`} key={index} onClick={
                    () =>{
                      props.onChangeChat(item.id);
                      setShowMessage(true);
                      setTalkHeight();
                  }}>
                    <div className="chat-list-item-info">
                      <Image className="circled" src={item.image} />
                      <p>{item.name}<small>{item.address}</small></p>
                    </div>
                    {item.unread > 0 && <Badge>{item.unread}</Badge>}
                    <Icon name="arrow-right" />
                  </div>
                )
              })}
            </Grid.cell>
            <Grid.cell className="chat-talk cell-start-7_md cell-end-12_md">
              <div ref={talkNav} className="chat-talk-nav">
                <Icon className="back" onClick={() => setShowMessage(false)} name="arrow-left" />
                <Image className="circled" src={props.currentChat.user.image} />
                <span>{props.currentChat.user.name}</span>
                <Icon onClick={() => setModalShow('delete')} name="trash"/>
              </div>
              <div ref={talkMessages} className="chat-talk-messages" onScroll={e => props.messagesScrollTop(e.target.scrollTop)}>
                {props.currentChat.chat.length == 0 &&
                  <div className="chat-talk-empty">
                    <Icon name="chat-filled"/>
                    Diga um Olá para iniciar a conversa
                  </div>
                }
                {props.currentChat.chat.map((item, index) => {
                  if (item.my) {
                    return (
                      <div className="chat-talk-message my" key={index}>{item.message}</div>
                    )
                  } else {
                    return (
                      <div className="chat-talk-message them" key={index}>{item.message}</div>
                    )
                  }
                })}
              </div>
              <div className="chat-talk-field">
                <Field maxLength={500} rows={1} onKeyDown={onEnterPress} ref={textareaRef} type="textarea"></Field>
                <Button className="circled primary" onClick={sendMessage}><Icon name="send" /></Button>
              </div>
            </Grid.cell>
          </Grid>

        }
      </Container>


        <Modal show={modalShow}>
          <Modal.content
            show={modalShow == 'delete'}
            disabled={modalLoading}
            className="size-2"
            onClose={() => setModalShow(false)}
            header={<b>Excluir Match</b>}
            footer={
              <div>
                <Button className="width-full" loading={modalLoading} onClick={() => {setModalLoading(true); props.onDeleteMatch(props.currentChat.id);}}>Excluir Match</Button>
                <Button className="width-full primary mt-1" disabled={modalLoading} onClick={() => setModalShow(false)}>Cancelar</Button>
              </div>}
          >
            <p className="text-center"><b>Tem certeza que deseja excluir esse Match?</b></p>
            <p className="text-center">Todas as conversas serão removidas e você não poderá dar Match com esse contato novamente</p>
          </Modal.content>
        </Modal>
    </Page>
    : 
    <Page name="chat" className={showMessage?'is-show-message':''} loading={props.loadingForm}>
      <Navbar chatCount={5} menuHighlighter="chat" />
      <Container center>
        {!props.chatList.length &&
          <Wrapper className="text-center mt-6">
            <b>Quer iniciar uma conversa?</b>
            <p>Quando você e outra pessoa dão um Match, você pode enviar mensagens para elas.</p>
            <Button className="primary full" onClick={() => props.onGotoMatch()}>Encontrar Match</Button>
          </Wrapper>
        }

        {props.chatList.length != 0 &&
          <Grid>
            <Grid.cell className="chat-list cell-start-1_md cell-end-5_md">
              {props.chatList.map((item, index) => {
                return (
                  <div className={`chat-list-item ${item.id === props.currentChat.id ? 'active' : ''}`} key={index} onClick={
                    () =>{
                      props.onChangeChat(item.id);
                      setShowMessage(true);
                      setTalkHeight();
                  }}>
                    <div className="chat-list-item-info">
                      <Image className="circled" src={item.image} />
                      <p>{item.name}<small>{item.address}</small></p>
                    </div>
                    {item.unread > 0 && <Badge>{item.unread}</Badge>}
                    <Icon name="arrow-right" />
                  </div>
                )
              })}
            </Grid.cell>
            <Grid.cell className="chat-talk cell-start-7_md cell-end-12_md">
              <div ref={talkNav} className="chat-talk-nav">
                <Icon className="back" onClick={() => setShowMessage(false)} name="arrow-left" />
                <Image className="circled" src={props.currentChat.user.image} />
                <span>{props.currentChat.user.name}</span>
                <Icon onClick={() => setModalShow('delete')} name="trash"/>
              </div>
              <div ref={talkMessages} className="chat-talk-messages" onScroll={e => props.messagesScrollTop(e.target.scrollTop)}>
                {props.currentChat.chat.length == 0 &&
                  <div className="chat-talk-empty">
                    <Icon name="chat-filled"/>
                    Diga um Olá para iniciar a conversa
                  </div>
                }
                {props.currentChat.chat.map((item, index) => {
                  if (item.my) {
                    return (
                      <div className="chat-talk-message my" key={index}>{item.message}</div>
                    )
                  } else {
                    return (
                      <div className="chat-talk-message them" key={index}>{item.message}</div>
                    )
                  }
                })}
              </div>
              <div className="chat-talk-field">
                <Field maxLength={500} rows={1} onKeyDown={onEnterPress} ref={textareaRef} type="textarea"></Field>
                <Button className="circled primary" onClick={sendMessage}><Icon name="send" /></Button>
              </div>
            </Grid.cell>
          </Grid>

        }
      </Container>


        <Modal show={modalShow}>
          <Modal.content
            show={modalShow == 'delete'}
            disabled={modalLoading}
            className="size-2"
            onClose={() => setModalShow(false)}
            header={<b>Excluir Match</b>}
            footer={
              <div>
                <Button className="width-full" loading={modalLoading} onClick={() => {setModalLoading(true); props.onDeleteMatch(props.currentChat.id);}}>Excluir Match</Button>
                <Button className="width-full primary mt-1" disabled={modalLoading} onClick={() => setModalShow(false)}>Cancelar</Button>
              </div>}
          >
            <p className="text-center"><b>Tem certeza que deseja excluir esse Match?</b></p>
            <p className="text-center">Todas as conversas serão removidas e você não poderá dar Match com esse contato novamente</p>
          </Modal.content>
        </Modal>
    </Page>
  );
}

export default Front;
