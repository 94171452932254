export const Types = {
  LOAD_STATES_REQUEST: '@states/LOAD_STATES_REQUEST',
  LOAD_STATES_SUCCESS: '@states/LOAD_STATES_SUCCESS',
  LOAD_STATES_FAILED: '@states/LOAD_STATES_FAILED',

  LOAD_CITIES_REQUEST: '@states/LOAD_CITIES_REQUEST',
  LOAD_CITIES_SUCCESS: '@states/LOAD_CITIES_SUCCESS',
  LOAD_CITIES_FAILED: '@states/LOAD_CITIES_FAILED',
}

export const Actions = {
  
  loadStatesRequest: () => ({
    type: Types.LOAD_STATES_REQUEST,
  }),
  
  loadStatesSuccess: (states) => ({
    type: Types.LOAD_STATES_SUCCESS,
    payload: { states }
  }),
  
  loadStatesFailed: (err) => ({
    type: Types.LOAD_STATES_FAILED,
    payload: { err }
  }),


  loadCitiesRequest: (stateId) => ({
    type: Types.LOAD_CITIES_REQUEST,
    payload: { stateId }
  }),
  
  loadCitiesSuccess: (cities) => ({
    type: Types.LOAD_CITIES_SUCCESS,
    payload: { cities }
  }),
  
  loadCitiesFailed: (err) => ({
    type: Types.LOAD_CITIES_FAILED,
    payload: { err }
  }),
}


