export const Types = {
  LOAD_CURRENT_CAMPAIGN_REQUEST: '@campaign/LOAD_CURRENT_CAMPAIGN_REQUEST',
  LOAD_CURRENT_CAMPAIGN_SUCCESS: '@campaign/LOAD_CURRENT_CAMPAIGN_SUCCESS',
  LOAD_CURRENT_CAMPAIGN_FAILED: '@campaign/LOAD_CURRENT_CAMPAIGN_FAILED',

  CHECK_CAMPAIGN_STATUS: '@campaign/CHECK_CAMPAIGN_STATUS',
}

export const Actions = {
  
  loadCurrerntCampaignRequest: (slug) => ({
    type: Types.LOAD_CURRENT_CAMPAIGN_REQUEST,
    payload: { slug }
  }),
  
  loadCurrerntCampaignSuccess: (campaign) => ({
    type: Types.LOAD_CURRENT_CAMPAIGN_SUCCESS,
    payload: { campaign }
  }),
  
  loadCurrerntCampaignFailed: (err) => ({
    type: Types.LOAD_CURRENT_CAMPAIGN_FAILED,
    payload: { err }
  }),

  checkCampaignStatus: () => ({
    type: Types.CHECK_CAMPAIGN_STATUS,
  })
}


