import produce from 'immer';
import { Types } from './actions';

const initialState = {
  states: [],
  cities: [],
}

export const statesbr = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOAD_STATES_REQUEST: {
        break;
      }
      case Types.LOAD_STATES_SUCCESS: {
        const { states } = action.payload
        draft.states = states

        break;
      }
      case Types.LOAD_STATES_FAILED: {
        break;
      }
      case Types.LOAD_CITIES_REQUEST: {
        break;
      }
      case Types.LOAD_CITIES_SUCCESS: {
        const { cities } = action.payload
        draft.cities = cities

        break;
      }
      case Types.LOAD_CITIES_FAILED: {
        break;
      }
      default:
    }
  });
}