export const Types = {
  SET_AUTH_INFOS: '@auth/SET_AUTH_INFOS',

  LOGOUT: '@auth/LOGOUT',

  SET_TOKEN_ON_REQUESTS: '@auth/SET_TOKEN_ON_REQUESTS',
  SET_PROFILE_COMPLETE: '@auth/SET_PROFILE_COMPLETE',
  SET_PROFILE_INFOS: '@auth/SET_PROFILE_INFOS',

  ACCESS_DENIED: '@auth/ACCESS_DENIED'
}

export const Actions = {
  setProfileInfos: (data) => ({
    type: Types.SET_PROFILE_INFOS,
    payload: { data }
  }),

  setAuthInfos: (token, campaign) => ({
    type: Types.SET_AUTH_INFOS,
    payload: { token, campaign }
  }),

  logout: () => ({
    type: Types.LOGOUT
  }),

  
  setTokenOnRequests: (token) => ({
    type: Types.SET_TOKEN_ON_REQUESTS,
    payload: { auth: { token: token } }
  }),

  setProfileComplete: (isComplete) => ({
    type: Types.SET_PROFILE_COMPLETE,
    payload: { isComplete }
  }),


  accessDenied: () => ({
    type: Types.ACCESS_DENIED
  })
}


