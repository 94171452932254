import React from 'react';

import {Link} from 'react-router-dom'

function Badge({children, ...props}) {

  return (
    <span className="Badge">
      {children}
    </span>
  );
}

export { Badge };
