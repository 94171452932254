const dateToAge = (birthdate) => {
  if(!birthdate) return;
  
  let born;

  if(birthdate.includes('/')) {
    const pieces = birthdate.split('/')
    const year = pieces[2]
    const month = pieces[1]
    const day = pieces[0]
    born = new Date(year, month, day);
  } else {
    born = new Date(birthdate);
  }
  
  const tody = new Date();

  return Math.floor(Math.ceil(Math.abs(born.getTime() - tody.getTime()) / (1000 * 3600 * 24)) / 365.25);
}

export default dateToAge;