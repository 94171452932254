import React, { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import dateToAge from '../../utils/dateToAge';
import { useInterval } from '../../utils/useInterval';

import Front from './front';
import { Actions as AuthActions } from '../../store/modules/auth/actions';
import { Actions as ProfileActions } from '../../store/modules/profile/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

const Index = (props) => {
  console.log(props)
  const history = useHistory();
  const dispatch = useDispatch();
  const profileReducer = useSelector(state => state.profile)
  const { currentCampaign } = useSelector(state => state.auth)

  const campaignReducer = useSelector(state => state.campaigns)

  useEffect(() => {
    if( props?.teste !== 'teste') {

      const included = 'included=city,city.state';
      dispatch(ProfileActions.loadProfileRequest(included));
      dispatch(CampaignActions.loadCurrerntCampaignRequest(currentCampaign))
      dispatch(CampaignActions.checkCampaignStatus())
    }
  }, []);
    
  useInterval(() => {
    dispatch(ProfileActions.loadMessagesResumeRequest())
  }, 20000);

  const logout = () => {
    dispatch(AuthActions.logout())
    history.push(`/${currentCampaign}`);
  }

  const deleteAccount = () => {
    dispatch(ProfileActions.deleteAccountRequest())
  }

  const getAddrress = () => {
    return `${profileReducer.currentCityName} - ${profileReducer.currentStateAcronym}`
  }

  return (
    <Front
      teste={props?.teste ? props.teste: undefined}
      userData={{
        name: props?.teste !== 'teste' ? profileReducer.name : '',
        email: props?.teste !== 'teste' ? profileReducer.email : '',
        age: props?.teste !== 'teste' ? dateToAge(profileReducer.birthdate): '',
        address: props?.teste !== 'teste' ? getAddrress() : '',
        image: props?.teste !== 'teste' ? profileReducer.image : '',
      }}

      onOkClick={() => {history.push("/")}}
      onLogout={props?.teste !== 'teste' ? logout : undefined}
      onDeleteAccount={props?.teste !== 'teste' ? deleteAccount : undefined}

      faqDownloadUrl={campaignReducer?.currentCampaign?.attributes?.doc_faq}
    />
    
  )
}

export default Index;
