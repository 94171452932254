import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import Front from './front';

import { useDispatch, useSelector } from 'react-redux';

import { Actions as AuthActions } from '../../store/modules/auth/actions';
import { Actions as CampaignActions } from '../../store/modules/campaigns/actions';

import { Profile } from '../../services/api/profile';

const Index = () => {
  // const { campaign_login } = useParams();

  const [loginUrl, setLoginUrl] = useState();

  // const dispatch = useDispatch();
  // const history = useHistory();
  const campaignReducer = useSelector(state => state.campaigns)

  // useEffect(() => {

  //   const mountLoginUrl = () => {
  //     const callback = `${process.env.REACT_APP_SITE_URL}/${campaign_login}`
  //     const api = process.env.REACT_APP_API_URL

  //     const login_url = `${api}/auth/spotify?campaign_slug=${campaign_login}&url_callback=${callback}`
  //     setLoginUrl(login_url)
  //   }

  //   const checkLoginFlow = () => {
  //     let url = new URLSearchParams(window.location.search)

  //     const campaign = url.get("campaign")
  //     const token = url.get("token")

  //     if(token && campaign) {
  //       dispatch(AuthActions.setAuthInfos(token, campaign));
  //       dispatch(AuthActions.setTokenOnRequests(token));
  //       checkProfileComplete();
  //     }
  //   }

  //   const checkProfileComplete = async () => {
  //     const included = 'included=musical_preferences';
  //     const response = await Profile.load(included)

  //     const { profile_complete } = response.data.data.attributes
      
  //     dispatch(AuthActions.setProfileInfos(response.data))
  //     dispatch(AuthActions.setProfileComplete(profile_complete))

  //     if(profile_complete) {
  //       history.push('/app/match');
  //     } else {
  //       history.push('/app/signin');
  //     }
  //   }

  //   const loadCampaignInfos = () => {
  //     dispatch(CampaignActions.loadCurrerntCampaignRequest(campaign_login))
  //   }

  //   checkLoginFlow();
  //   mountLoginUrl();
  //   loadCampaignInfos();
  // }, []);

  const { attributes } = campaignReducer?.currentCampaign || {}

  return (
    <Front
      title={attributes?.name}
      subtitle={attributes?.subname}
      termsUrl={attributes?.doc_terms}
      coverImagesSrc={{
        mobile: attributes?.image_mobile, 
        desktop: attributes?.image_desktop
      }}
      loginUrl={loginUrl}
      loading={campaignReducer.loading}
      finishLoading={campaignReducer.finishLoading}
    />
  )
}

export default Index;
