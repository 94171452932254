export const Types = {
  LOAD_USER_PREFERENCES_REQUEST: '@preferences/LOAD_USER_PREFERENCES_REQUEST',
  LOAD_USER_PREFERENCES_SUCCESS: '@preferences/LOAD_USER_PREFERENCES_SUCCESS',
  LOAD_USER_PREFERENCES_FAILED: '@preferences/LOAD_USER_PREFERENCES_FAILED',

  LOAD_MORE_USER_PREFERENCES_REQUEST: '@preferences/LOAD_MORE_USER_PREFERENCES_REQUEST',
  LOAD_MORE_USER_PREFERENCES_SUCCESS: '@preferences/LOAD_MORE_USER_PREFERENCES_SUCCESS',
  LOAD_MORE_USER_PREFERENCES_FAILED: '@preferences/LOAD_MORE_USER_PREFERENCES_FAILED',

  LIKE_URI_REQUEST: '@preferences/LIKE_URI_REQUEST',
  LIKE_URI_SUCCESS: '@preferences/LIKE_URI_SUCCESS',
  LIKE_URI_FAILED: '@preferences/LIKE_URI_FAILED',

  DISLIKE_URI_REQUEST: '@preferences/DISLIKE_URI_REQUEST',
  DISLIKE_URI_SUCCESS: '@preferences/DISLIKE_URI_SUCCESS',
  DISLIKE_URI_FAILED: '@preferences/DISLIKE_URI_FAILED',
}

export const Actions = {
  
  loadUserPreferencesRequest: (withAddress = false, page) => ({
    type: Types.LOAD_USER_PREFERENCES_REQUEST,
    payload: { withAddress, page }
  }),
  
  loadUserPreferencesSuccess: (data) => ({
    type: Types.LOAD_USER_PREFERENCES_SUCCESS,
    payload: { data }
  }),
  
  loadUserPreferencesFailed: (err) => ({
    type: Types.LOAD_USER_PREFERENCES_FAILED,
    payload: { err }
  }),



  loadMoreUserPreferencesRequest: (withAddress = false, page) => ({
    type: Types.LOAD_MORE_USER_PREFERENCES_REQUEST,
    payload: { withAddress, page }
  }),
  
  loadMoreUserPreferencesSuccess: (data) => ({
    type: Types.LOAD_MORE_USER_PREFERENCES_SUCCESS,
    payload: { data }
  }),
  
  loadMoreUserPreferencesFailed: (err) => ({
    type: Types.LOAD_MORE_USER_PREFERENCES_FAILED,
    payload: { err }
  }),


  likeUriRequest: (id, currentUserIndex, kind) => ({
    type: Types.LIKE_URI_REQUEST,
    payload: { id, currentUserIndex, kind }
  }),
  
  likeUriSuccess: (id, currentUserIndex, kind) => ({
    type: Types.LIKE_URI_SUCCESS,
    payload: { id, currentUserIndex, kind }
  }),
  
  likeUriFailed: (err) => ({
    type: Types.LIKE_URI_FAILED,
    payload: { err }
  }),



  dislikeUriRequest: (id, currentUserIndex, kind) => ({
    type: Types.DISLIKE_URI_REQUEST,
    payload: { id, currentUserIndex, kind }
  }),
  
  dislikeUriSuccess: (id, currentUserIndex, kind) => ({
    type: Types.DISLIKE_URI_SUCCESS,
    payload: { id, currentUserIndex, kind }
  }),
  
  dislikeUriFailed: (err) => ({
    type: Types.DISLIKE_URI_FAILED,
    payload: { err }
  }),
}


