import produce from 'immer';
import { Types } from './actions';
import { calcMatch } from '../../../utils/calcMatch';

import dateToAge from '../../../utils/dateToAge';

import { extractArtistInfos, extractTrackInfos } from '../../../utils/extractArtistTrackInfos';

const initialState = {
  preferences: [],
}

export const userPreferences = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOAD_USER_PREFERENCES_REQUEST: {
        break;
      }
      case Types.LOAD_USER_PREFERENCES_SUCCESS: {
        const { data } = action.payload
        

        const normalizedData = data.map(item => {
          return normalizeUserInfos(item)
        });

        draft.preferences = normalizedData
        break;
      }
      case Types.LOAD_MORE_USER_PREFERENCES_REQUEST: {
        break;
      }
      case Types.LOAD_MORE_USER_PREFERENCES_SUCCESS: {
        const { data } = action.payload

        const normalizedData = data.map(item => {
          return normalizeUserInfos(item)
        });

        if(!normalizedData.length) return;
        
        draft.preferences = [...draft.preferences, ...normalizedData ]
        break;
      }
      case Types.LOAD_MORE_USER_PREFERENCES_FAILED: {
        break;
      }
      case Types.LOAD_USER_PREFERENCES_FAILED: {
        draft.loadingFinish = true
        break;
      }
      case Types.LIKE_URI_SUCCESS: {
        const { id, currentUserIndex, kind } = action.payload

        if(kind === 'artist') {
          const index = draft.preferences[currentUserIndex].topArtists.findIndex(item => parseInt(item.id) === parseInt(id))
          draft.preferences[currentUserIndex].topArtists[index].combine = true
        }

        if(kind === 'music') {
          const index = draft.preferences[currentUserIndex].topMusics.findIndex(item => parseInt(item.id) === parseInt(id))
          draft.preferences[currentUserIndex].topMusics[index].combine = true
        }
        break;
      }
      case Types.DISLIKE_URI_SUCCESS: {
        const { id, currentUserIndex, kind } = action.payload

        if(kind === 'artist') {
          const index = draft.preferences[currentUserIndex].topArtists.findIndex(item => parseInt(item.id) === parseInt(id))
          draft.preferences[currentUserIndex].topArtists[index].combine = false
        }

        if(kind === 'music') {
          const index = draft.preferences[currentUserIndex].topMusics.findIndex(item => parseInt(item.id) === parseInt(id))
          draft.preferences[currentUserIndex].topMusics[index].combine = false
        }
        break;
      }
      default:
    }
  });
}

function normalizeUserInfos(user) {
  return {
    id: user.id,
    image: user.avatar,
    name: user.name,
    age: dateToAge(user.birthdate),
    address: `${user.city.name}, ${user.city.state.acronym}`,
    description: user.description,
    progress: calcMatch(user.musical_preferences),
    topArtists: normalizeArtists(user),
    topMusics: normalizeMusics(user),
  }
}

function normalizeArtists(user) {
  return user.musical_preferences.filter(item => item.kind === 'artists').map(item => ({
    combine: checkCombine(user.uri_compatibility_ids, item.uri.id),
    id: item.uri.id,
    name: extractArtistInfos(item)[0],
    image: extractArtistInfos(item)[1],
  }))
}

function normalizeMusics(user) {
  return user.musical_preferences.filter(item => item.kind === 'tracks').map(item => ({
    combine: checkCombine(user.uri_compatibility_ids, item.uri.id),
    id: item.uri.id,
    name: extractTrackInfos(item)[0],
    artist: extractTrackInfos(item)[1],
    image: extractTrackInfos(item)[2],
  }))
}

export const checkCombine = (compatibility_ids, current_id) => {
  const isPresent = compatibility_ids.find(item => parseInt(item) === parseInt(current_id))
  return !!isPresent
}
