import { getStoredInfos } from './getStoredInfos';
import { extractTrackInfos, extractArtistInfos } from './extractArtistTrackInfos';

import Jsona from 'jsona';
const dataFormatter = new Jsona();

export const calcMatch = (preferences) => {
  const stored = getStoredInfos();

  const data = dataFormatter.deserialize(stored.profile)

  const user_1_list = calc(preferences)
  const user_2_list = calc(data.musical_preferences)

  const intersection = user_1_list.filter(item => user_2_list.includes(item));
  const total = intersection.length

  if(total === 0) return 14
  if(total === 1) return 38
  if(total === 2) return 63
  if(total === 3) return 89
  if(total > 3) return 100
}

const calc = (preferences) => {
  const artist_names_1 = preferences.filter(item => item.kind === 'artists').map(item => {
    return extractArtistInfos(item)[0]
  });

  const artist_names_2 = preferences.filter(item => item.kind === 'tracks').map(item => {
    return extractTrackInfos(item)[1]
  });

  const user_list = [...artist_names_1, ...artist_names_2]
  const user_list_uniq = [...new Set(user_list)]

  return user_list_uniq;
}