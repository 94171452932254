export const Types = {
  LOAD_PROFILE_REQUEST: '@profile/LOAD_PROFILE_REQUEST',
  LOAD_PROFILE_SUCCESS: '@profile/LOAD_PROFILE_SUCCESS',
  LOAD_PROFILE_FAILED: '@profile/LOAD_PROFILE_FAILED',

  UPDATE_PROFILE_REQUEST: '@profile/UPDATE_PROFILE_REQUEST',
  UPDATE_PROFILE_SUCCESS: '@profile/UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILED: '@profile/UPDATE_PROFILE_FAILED',

  DELETE_ACCOUNT_REQUEST: '@profile/DELETE_ACCOUNT_REQUEST',
  DELETE_ACCOUNT_SUCCESS: '@profile/DELETE_ACCOUNT_SUCCESS',
  DELETE_ACCOUNT_FAILED: '@profile/DELETE_ACCOUNT_FAILED',
  
  
  LOAD_MESSAGES_RESUME_REQUEST: '@profile/LOAD_MESSAGES_RESUME_REQUEST',
  LOAD_MESSAGES_RESUME_SUCCESS: '@profile/LOAD_MESSAGES_RESUME_SUCCESS',
}

export const Actions = {
  
  loadProfileRequest: (included = false) => ({
    type: Types.LOAD_PROFILE_REQUEST,
    payload: { included }
  }),
  
  loadProfileSuccess: (data, included) => ({
    type: Types.LOAD_PROFILE_SUCCESS,
    payload: { data, included }
  }),
  
  loadProfileFailed: (err) => ({
    type: Types.LOAD_PROFILE_FAILED,
    payload: { err }
  }),


  updateProfileRequest: (avatar, name, email, birthdate, genre, preferences, description, cityId) => ({
    type: Types.UPDATE_PROFILE_REQUEST,
    payload: { avatar, name, email, birthdate, genre, preferences, description, cityId }
  }),
  
  updateProfileSuccess: () => ({
    type: Types.UPDATE_PROFILE_SUCCESS
  }),
  
  updateProfileFailed: (err) => ({
    type: Types.UPDATE_PROFILE_FAILED,
    payload: { err }
  }),


  deleteAccountRequest: () => ({
    type: Types.DELETE_ACCOUNT_REQUEST,
  }),
  
  deleteAccountSuccess: () => ({
    type: Types.DELETE_ACCOUNT_SUCCESS
  }),

  deleteAccountFailed: () => ({
    type: Types.DELETE_ACCOUNT_FAILED,
  }),


  loadMessagesResumeRequest: () => ({
    type: Types.LOAD_MESSAGES_RESUME_REQUEST,
  }),
  
  loadMessagesResumeSuccess: (resume) => ({
    type: Types.LOAD_MESSAGES_RESUME_SUCCESS,
    payload: { resume }
  }),
}


