import React from 'react';
import {Icon, Badge, Container} from '../../components';
import { useSelector } from 'react-redux';

import {Link} from 'react-router-dom'

function Navbar({chatCount, ...props}) {

  const { messagesResume } = useSelector(state => state.profile)

  const { currentCampaign } = useSelector(state => state.auth)
  console.log(currentCampaign);

  return (
    <nav className={`${props.teste === 'teste' ? 'campaign_gusttavo_navbar' : '' } Navbar`} >
      <Container center className={props.teste === 'teste' ? 'campaign_gusttavo_navbar' : ''}>
        <ul>
          <li className={props.menuHighlighter === 'perfil'?'active':''}>
            <Link to="/app/profile"><Icon name={`user-circled${props.menuHighlighter === 'perfil' ? '-filled':''}`} /> <span>Perfil</span></Link>
          </li>
          <li className={props.menuHighlighter === 'match'?'active':''}>
            <Link to="/app/match"><Icon name={`heart${props.menuHighlighter === 'match' ? '-filled':''}`} /> <span>Match</span></Link>
          </li>
          <li className={props.menuHighlighter === 'chat'?'active':''}>
            <Link to="/app/chat"><Icon  name={`chat${props.menuHighlighter === 'chat' ? '-filled':''}`} /> <span>Chat</span></Link>
            {messagesResume?.unread > 0 && <Badge className="error">{messagesResume.unread}</Badge>}
          </li>
        </ul>
      </Container>
    </nav>
  );
}

export { Navbar };
