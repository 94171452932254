import { all, call, takeLatest, put } from 'redux-saga/effects';
import { api } from '../../../config/ApiConfig';

import { Types, Actions } from './actions';

function setToken({ payload }) {
  if(!payload) return;

  const { token } = payload.auth;

  if(token) {
    api.defaults.headers.Authorization = `Token token=${token}`;
  }
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest(Types.SET_TOKEN_ON_REQUESTS, setToken),
])