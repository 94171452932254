import React, { useState, useEffect } from 'react';
import {Field, Button} from '../../components';

function AccountForm({data, ...props}) {
  const [disableSubmmit, setDisableSubmmit] = useState(true);

  const handleChange = (key, value) => {
    const items = {...data};
    items[key] = value;
    props.setData({...items});
  };

  const handleChangeState = (value) => {
    handleChange('state', value)
    props.onChangeState(value);
  }

  useEffect(() => {
    const { name, email, genre, city, date, genreFemale, genreMale, genreNotBinary, genreOther } = data

    if(
      name?.length && (genre?.length && genre !== '-') &&
      email?.length && city?.length && date?.length &&
      (genreFemale || genreMale || genreNotBinary || genreOther) &&
      (city?.length && city !== '- Selecionar -')
    ) {
      setDisableSubmmit(false)
    } else {
      setDisableSubmmit(true)
    }
  }, [data]);

  return (
    <div className={props.teste === 'teste' ? 'profile-fields' : ''}>
      {props.teste === 'teste' && !props.notCreate ?
        <div className='title-pic'>
          <h3 className="text-center">Edite seu perfil</h3>
          <Field
            required
            type="image"
            value={data.image}
            className="circled"
            maxWidth={640}
            maxHeight={640}
            onChange={imageSrc => props.setImageData({'image': imageSrc})}
          >Enviar foto</Field>
        </div>
        : 
        <Field
            required
            type="image"
            value={data.image}
            className="circled"
            maxWidth={640}
            maxHeight={640}
            onChange={imageSrc => props.setImageData({'image': imageSrc})}
        >Enviar foto</Field>
      }

        <Field
          required
          type="text"
          value={data.name}
          onChange={e => handleChange('name', e.target.value)}
        >Nome</Field>

        <Field
          required
          type="email"
          value={data.email}
          onChange={e => handleChange('email', e.target.value)}
        >Email</Field>

        <Field
          required
          type="textarea"
          value={data.description}
          maxLength={200}
          maxLengthDisplay={true}
          onChange={e => handleChange('description', e.target.value)}
        >Descrição</Field>

        <Field
          required
          type="date"
          value={data.date}
          onChange={e => handleChange('date', e.target.value)}
        >Data de nascimento</Field>

        <Field
          required
          type="select"
          options={props.statesOptions}
          value={data.state}
          onChange={e => handleChangeState(e.target.value)}
        >Estado</Field>

        <Field
          required
          disabled={props.disableCities}
          type="select"
          options={props.citiesOptions}
          value={data.city}
          onChange={e => handleChange('city', e.target.value)}
        >Cidade</Field>

        <Field
          required
          type="select"
          options={props.genreOptions}
          value={data.genre}
          onChange={e => handleChange('genre', e.target.value)}
        >Gênero</Field>

        <p className="mb-0">Quais tipos de perfis você quer ver?</p>
        <small className="text-muted mb-1 block">Escolha ao menos um</small>

        <Field
          type="switch"
          checked={data.genreFemale}
          onChange={e => handleChange('genreFemale', e.target.checked)}
        >Mulher</Field>

        <Field
          type="switch"
          checked={data.genreMale}
          onChange={e => handleChange('genreMale', e.target.checked)}
        >Homem</Field>
        
        {props.teste !== 'teste' ? 
          <>
            <Field
              type="switch"
              className="testes"
              checked={data.genreNotBinary}
              onChange={e => handleChange('genreNotBinary', e.target.checked)}
              
              >Não-binário</Field>

            <Field
              type="switch"
              className="testes"
              checked={data.genreOther}
              onChange={e => handleChange('genreOther', e.target.checked)}
              
              >Outro</Field> 
          </>
          : 
          ''}
        

        {props.teste === 'teste' && props.notCreate ? '' : <Button className="primary full" disabled={disableSubmmit} onClick={() => props.onSaveClick()}>{props.saveButtonLabel}</Button>}
        
    </div>
  );
}

export { AccountForm };
