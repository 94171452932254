export const extractArtistInfos = (data) => {
  const partials = data.extra_info.split(':image=>')
  const nameAndImg = partials[1].split(':name=>')
  
  const name = nameAndImg[1].substring(1, nameAndImg[1].length - 2)
  const image = nameAndImg[0].substring(1, nameAndImg[0].length - 3);

  return [name, image];
}

export const extractTrackInfos = (track) => {
  const partials = track.extra_info.split(':image=>')
  const imgAndTrackName = partials[1].split(':name=>')

  const artistName = partials[0].substring(12, partials[0].length - 3)
  const img = imgAndTrackName[0].substring(1, imgAndTrackName[0].length - 3)
  const trackName = imgAndTrackName[1].substring(1, imgAndTrackName[1].length - 2)
  
  return [trackName, artistName, img];
}