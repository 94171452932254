import { all, call, takeLatest, put } from 'redux-saga/effects';
import { Likes } from '../../../services/api/likes';

import { Types, Actions } from './actions';

function* like({ payload }) {
  const { userId } = payload

  const data = {
    like: {
      kind: 'like',
      user_liked_id: userId
    }
  }

  try {
    const response = yield call(Likes.like, data)
    // console.log(response.data)

    const match = response.data.data.relationships.match

    yield put(Actions.likeSuccess(match))
  } catch(err) {

  }
}

function* dislike({ payload }) {
  const { userId } = payload

  const data = {
    like: {
      kind: 'not_interested',
      user_liked_id: userId
    }
  }

  try {
    yield call(Likes.dislike, data)

    yield put(Actions.dislikeSuccess())
  } catch(err) {

  }
}

export default all([
  takeLatest(Types.LIKE_REQUEST, like),
  takeLatest(Types.DISLIKE_REQUEST, dislike),
]);
