import produce from 'immer';
import { Types } from './actions';

import { extractArtistInfos, extractTrackInfos } from '../../../utils/extractArtistTrackInfos';

const initialState = {
  name: null,
  email: null,
  genre: null,
  birthdate: null,
  address: null,
  image: null,
  description: null,
  preferences: [],

  topArtists: [],
  topMusics: [],

  currentStateAcronym: null,
  currentCityId: null,
  currentCityName: null,

  messagesResume: null,

  updatingProfile: false,
  loadEnd: false,
}

export const profile = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOAD_PROFILE_REQUEST: {
        draft.loadEnd = false
        break;
      }
      case Types.LOAD_PROFILE_SUCCESS: {
        const { data } = action.payload
        
        const { name, email, avatar, birthdate, genre, preference, description, resume } = data

        draft.name = name
        draft.email = email
        draft.genre = genre
        draft.birthdate = mask(birthdate) 
        draft.image = avatar
        draft.preferences = preference
        draft.description = description

        draft.currentStateAcronym = data?.city?.state?.acronym
        draft.currentCityId = data?.city?.id
        draft.currentCityName = data?.city?.name

        draft.topArtists = normalizeArtists(data?.musical_preferences)
        draft.topMusics = normalizeMusics(data?.musical_preferences)
        draft.messagesResume = resume?.messages

        draft.loadEnd = true
        break;
      }
      case Types.LOAD_PROFILE_FAILED: {
        draft.loadEnd = false
        break;
      }
      case Types.UPDATE_PROFILE_REQUEST: {
        draft.updatingProfile = true;
        break;
      }
      case Types.UPDATE_PROFILE_SUCCESS: {
        draft.updatingProfile = false;
        break;
      }
      case Types.UPDATE_PROFILE_FAILED: {
        draft.updatingProfile = false;
        break;
      }
      case Types.LOAD_MESSAGES_RESUME_SUCCESS: {
        const { resume } = action.payload
        draft.messagesResume = resume.messages
        break;
      }
      default:
    }
  });
}

function normalizeArtists(data) {
  return data.filter(item => item.kind === 'artists').map(item => ({
    combine: true,
    name: extractArtistInfos(item)[0],
    image: extractArtistInfos(item)[1],
  }))
}

function normalizeMusics(data) {
  return data.filter(item => item.kind === 'tracks').map(item => ({
    combine: true,
    name: extractTrackInfos(item)[0],
    artist: extractTrackInfos(item)[1],
    image: extractTrackInfos(item)[2],
  }))
}

const mask = text => {
  if(!text) return ''

  const partials = text.split('-')
  return `${partials[2]}/${partials[1]}/${partials[0]}`
}