import React from 'react';
import { PersistGate } from 'redux-persist/integration/react';

import './config/ReactotronConfig';

import { Provider } from 'react-redux';
import { store, persistor }   from './store';

import './App.scss';

import { Router } from "react-router-dom";

import history from './services/history';

import Routes from './routes';

function App(props) {
  // ---> @valmy, aqui seleciona a cor primária.

  const themeColor = localStorage.getItem('campaign_color');
  if (themeColor) {
    document.documentElement.style.setProperty("--color-primary", themeColor);
  }
  // <---

  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <Routes/>
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
