import { all, call, takeLatest, put } from 'redux-saga/effects';
import { States } from '../../../services/api/states';

import { Types, Actions } from './actions';

function* loadStates() {

  try {
    const response = yield call(States.all)
    const states = response.data.data

    yield put(Actions.loadStatesSuccess(states))
  } catch(err) {
    yield put(Actions.loadStatesFailed(err))
  }
}

function* loadCities({ payload }) {
  const { stateId } = payload

  try {
    const response = yield call(States.cities, stateId)
    const cities = response.data.data

    yield put(Actions.loadCitiesSuccess(cities))
  } catch(err) {
    yield put(Actions.loadCitiesFailed(err))
  }
}

export default all([
  takeLatest(Types.LOAD_STATES_REQUEST, loadStates),
  takeLatest(Types.LOAD_CITIES_REQUEST, loadCities),
])