import React from "react";

import logo from "../../assets/images/loginV3/logo.png";
import start from "../../assets/images/loginV3/start.png";

function Front(props) {
  return (
    <main id="solteiro_gusttavo">
      <div className="info_container">
        <div className="info_content">
          <div className="logo_content">
            <img
              id="logo"
              src={logo}
              alt="Embaixador Cupido"
              title="Embaixador Cupido"
            />
          </div>
          <div className="button">
            <img
              id="login_button"
              src={start}
              alt="Entrar com YouTube"
              title="Entrar com YouTube"
            />
          </div>
        </div>
      </div>
    </main>
  );
}

export default Front;
