import produce from 'immer';
import { Types } from './actions';

const initialState = {
  match: false,
  loadingLike: false,
}

export const likes = (state = initialState, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case Types.LIKE_REQUEST: {
        draft.match = false
        draft.loadingLike = true
        break;
      }
      case Types.LIKE_SUCCESS: {
        const { match } = action.payload

        draft.match = match.data ? match : false
        draft.loadingLike = false
        break;
      }
      case Types.DISLIKE_REQUEST: {
        draft.match = false
        draft.loadingLike = true
        break;
      }
      case Types.DISLIKE_SUCCESS: {
        draft.match = false
        draft.loadingLike = false
        break;
      }
      case Types.RESET_MATCH: {
        draft.match = false
        break;
      }
      default:
    }
  });
}