import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import history from '../services/history';

import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  isLogin = false,
  notFound = false,
  endCampaign = false,
  ...rest
}) {
  const { logged, profileComplete } = store.getState().auth
  
  const currentPath = window.location.pathname

  if(notFound || endCampaign) {
    return <Route {...rest} component={Component} />
  }

  if(isLogin) return <Route {...rest} component={Component} />

  if(!logged && isPrivate) {
    history.goBack();
  }

  if(logged && !isPrivate) return toProfile()

  if(logged && currentPath !== '/app/signin' && !profileComplete) return toSignin()

  return <Route {...rest} component={Component} />
}

const toSignin = () => <Route render={ () => <Redirect to="/app/signin" /> } />
const toProfile = () => <Route render={ () => <Redirect to="/app/profile" /> } />
//const toLogin = (campaign) => <Route render={ () => <Redirect to={`/${campaign}`} /> } />
