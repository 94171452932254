import React from 'react';
import {
  Page, Divider, Container, Button, Grid, Image, Logo,
  LoginLayout
} from '../../components';

function Front(props) {

  return (
    <Page name="end">
      <Container center>
        <Logo />
        <h6>Essa página não existe mais :-(</h6>
        <p>Essa página foi removida e não é mais possível acessá-la.</p>
      </Container>
    </Page>
  );
}

export default Front;
