import React from 'react';

import defaultImage from "../../assets/images/default.jpg";
import playIcon from "../../assets/images/loginV3/play_icon.svg";
import heartFull from "../../assets/images/loginV3/heart_full.svg";
import star from "../../assets/images/loginV3/star_music.svg";
import starMusic from "../../assets/images/loginV3/star.svg";
import heart from "../../assets/images/loginV3/heart.svg";

function MusicList(props) {

  const musicas = [{
    id: 1,
    imagem: defaultImage,
    titulo: 'Café e amor',
    autor: 'Gusttavo Lima',
    curtida: false
  },{
    id: 2,
    imagem: defaultImage,
    titulo: 'Solteiro',
    autor: 'Gusttavo Lima',
    curtida: true
  },
  {
    id: 3,    
    imagem: defaultImage,
    titulo: 'Fora do Comum',
    autor: 'Gusttavo Lima',
    curtida: true
  },{
    id: 4,
    imagem: defaultImage,
    titulo: 'Estou Apaixonado',
    autor: 'Gusttavo Lima',
    curtida: false
  },{
    id: 5,
    imagem: defaultImage,
    titulo: 'titulo',
    autor: 'Gusttavo Lima',
    curtida: false
  }]

  return (
    <>
      <div className={`music-header ${props.header ? 'match-header' : ''}`}>
        <img
          id="playIcon"
          src={playIcon}
          alt="Play Icon"
          title="Play Icon"
        />

        {props.title ? 
          <div className="music-header-title">
            <span>{props.title}</span>
            {props.subtitle ? 
              <span className="info">
                <img
                  src={star}
                  alt='Estrela'
                />
                {' '}Músicas em comum
                </span> 
            : ''} 
          </div>
        :
          <div className="music-header-title">
            <span>Monte sua playlist: *</span>
            <span className="info">*Escolha ao menos 3 músicas.</span>
          </div>
        }
        
      </div>
      <div className="music-content">
        {musicas.map((musica) => (
          <div className="music-info">
            <div className="music-image"> 
              <img
                id={musica.id}
                src={musica.imagem}
                alt={musica.titulo}
              />
            <div className="text-info">
              <div className="title">{musica.titulo}</div>
              <div className="author">{musica.autor}</div>
            </div>
            </div>
            {props.star ? 
              musica.curtida ?
                <div className="star">
                  <img
                    id={musica.id}
                    src={starMusic}
                    alt={musica.titulo}
                  />
                </div> 
              : 
                ''
            : 
            <div className="icon" onClick={()=> {console.log('clicou na musica', musica.id)}}>
              <img
                id={musica.id}
                src={musica.curtida ? heartFull : heart }
                alt={musica.titulo}
              />
            </div> }
            
          </div>
        ))}
      </div>
    </>
  );
}

export { MusicList };
