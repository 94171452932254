import React, {Fragment, useEffect} from 'react';
import {Icon, Image, Slider, Progress, Grid} from '../../components';
import defaultImage from '../../assets/images/default.jpg';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import {Link} from 'react-router-dom'
import { MusicList } from '../MusicList';

function ProfileCard({userData, ...props}) {

  useEffect(() => {
    // console.log(userData)
  }, [userData])

  return (
    props.teste ?
      <div className="ProfileCardGusttavo">
        <Grid>
          <Grid.cell className="cell-5_lg">
            <div className="ChooseMusic">
              <div className="choose-music-profile-info">
                <Image
                  className="choose-music-profile-avatar"
                  src={userData.image ? userData.image : defaultImage}
                />
              </div>
              <div className="choose-music-profile-info-wrapper">
                <h3>{userData.name}, {userData.age}</h3>
                <div className="address">{userData.address}</div>
                <p className="choose-music-profile-description">
                  {userData.description}
                </p>
              </div>
            </div>
          </Grid.cell>

          <Grid.cell className="cell-7_lg">
            <MusicList title={`O que ${userData.name} está curtindo:`} subtitle header star />
          </Grid.cell>
        </Grid>
      </div>
      :
      <div className="ProfileCard">
        <Grid>
          <Grid.cell className="cell-5_lg">
            <div className="profile-card-info">
              <Image className="profile-card-avatar" src={userData.image ? userData.image : defaultImage} />
              <div className="profile-card-info-wrapper">
                <small>Match Musical</small>
                <h3>{userData.name}, {userData.age}</h3>
                <p className="small">{userData.address}</p>
                <CircularProgressbar
                  value={userData.progress}
                  text={`${userData.progress}%`}
                  strokeWidth={12}
                  />
              </div>
            </div>
            <p className="profile-card-description">{userData.description}</p>
          </Grid.cell>

          <Grid.cell className="cell-7_lg"><h3 className="h5">Top músicas</h3>
            <Slider>
              <Fragment>
                {userData.topMusics.length == 0 &&
                  <p className="no-data">Esse participante não tem Top músicas.</p>
                }
                {userData.topMusics.map((item, index) => {
                  return (
                    <div key={index} onClick={() => props.onLikeMusic ? props.onLikeMusic(item.id, item.combine) : {}} >
                      <Image className="rounded squared mb-1" src={item.image} />
                      <div className="flex">

                        <Icon name={`heart${item.combine?'-filled':''}`} />
                        <div className="text-left text-ellipsis-2">
                          <div>{item.name}</div>
                          <p className="text-muted small text-ellipsis-2">{item.artist}</p>

                        </div>
                      </div>
                    </div>
                  )
                })}
              </Fragment>
            </Slider>

            <h3 className="h5">Top artista</h3>
            <Slider>
              <Fragment>
                {userData.topArtists.length == 0 &&
                  <p className="no-data">Esse participante não tem Top artistas.</p>
                }
                {userData.topArtists.map((item, index) => {
                  return (
                    <div key={index} onClick={() => props.onLikeArtist ? props.onLikeArtist(item.id, item.combine) : {}}>
                      <Image className="circled mb-1" src={item.image} />
                      <div className="flex text-left">
                        <Icon name={`heart${item.combine?'-filled':''}`} />
                        <span className="text-ellipsis-2">{item.name}</span>
                      </div>
                    </div>
                  )
                })}
              </Fragment>
            </Slider>
          </Grid.cell>
        </Grid>
      </div>
  );
}

export { ProfileCard };
