import { combineReducers } from 'redux';

import { auth } from './auth/reducer';
import { profile } from './profile/reducer';
import { campaigns } from './campaigns/reducer';
import { statesbr } from './states/reducer';
import { userPreferences } from './preferences/reducer';
import { likes } from './likes/reducer';
import { matches } from './matches/reducer';

const appReducer = combineReducers({
  auth,
  profile,
  campaigns,
  statesbr,
  userPreferences,
  likes,
  matches,
});

export default appReducer;