import React, { useState, useEffect } from "react";
import btnPlay from "../../assets/images/loginV2/queue/btn_play.png";
import peopleAnimation from "../../assets/images/loginV2/queue/people_animation.gif";
import close from "../../assets/images/loginV2/queue/close.svg";

const songs = {
  1: {
    name: "Love On Top - Beyoncé",
    id: "1z6WtY7X4HQJvzxC4UgkSf",
    index: 5,
  },
  2: {
    name: "If I Ain't Got You - Alicia Keys",
    id: "3XVBdLihbNbxUwZosxcGuJ",
    index: 2,
  },
  3: {
    name: "Love Galore (feat. Travis Scott) - SZA",
    id: "0q75NwOoFiARAVp4EXU4Bs",
    index: 4,
  },
};

const Queue = ({ checkProfileComplete }) => {
  const [currentSong, setCurrentSong] = useState(null);
  const [position, setPosition] = useState(30);
  const [countdownStarted, setCountdownStarted] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  // const [progressBar, setProgressBar] = useState("");

  useEffect(() => {
    if (!currentSong) {
      setCurrentSong(Math.floor(Math.random() * 3) + 1);
    }
  }, [currentSong]);
  
  useEffect(() => {
    if (countdownStarted) {
      let watchedTimeInterval = null;
  
      if (position !== null && position > 0) {
        watchedTimeInterval = setInterval(() => {
          setPosition(prevPosition => prevPosition - 1);
        }, 1000);
      } else if (position === 0) {
        checkProfileComplete();
      }
  
      return () => {
        clearInterval(watchedTimeInterval);
      };
    }
  }, [position, countdownStarted]);

  useEffect(() => {
    setShowPopup(true);

    if (currentSong) {
      const loadSpotifyWebPlaybackSDK = () => {
        const tag = document.createElement('script');
        tag.src = "https://open.spotify.com/embed-podcast/iframe-api/v1";
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
      };
  
      loadSpotifyWebPlaybackSDK();
  
      window.onSpotifyIframeApiReady = (IFrameAPI) => {
        const element = document.getElementById('embed-iframe');

        const options = {
          uri: `spotify:track:${songs[currentSong].id}`,
          trackIndex: songs[currentSong].index - 1,
        };
  
        const callback = (EmbedController) => {
          document.getElementById('btn_starts_playing').addEventListener("click", () => EmbedController.play());
  
          EmbedController.addListener('playback_update', e => {
            console.log(e);
          });
        };
  
        IFrameAPI.createController(element, options, callback);
      };
    }
  }, [currentSong]);

  const handlePlayVideo = () => {
    setShowPopup(false);
    setCountdownStarted(true);
  };

  return (
    <main id="queue">
      <div id="plate">
        <h1>{position}º</h1>
        <h2>lugar na fila</h2>
      </div>
      
      <img
        id="people_waiting"
        src={peopleAnimation}
        alt="Pessoas na fila"
        title="Pessoas na fila"
      />

      <div id="phone_player">
        <button
          type="button"
          id="btn_starts_playing"
          className="btn-play"
          onClick={handlePlayVideo}
        >
          <img src={btnPlay} alt="Play" title="Play" />
        </button>

        <p id="phone_text">
          <span>Tocando agora: </span><br/>
          {songs[currentSong] && songs[currentSong].name}
        </p>
      </div>

      <div id="accelerate_queue_popup" className={showPopup ? "show_popup_animation" : ""}>
        <div className="popup_playlist">
          <button
            type="button"
            className="btn_close_popup"
            onClick={() => setShowPopup(false)}
          >
            <img src={close} alt="Fechar" title="Fechar" />
          </button>

          <h3>
            Que tal criar um clima romântico ouvindo nossa rádio? Beyoncé, SZA, Alicia Keys e muito mais!
          </h3>
        </div>
      </div>

      <div id="hidden_spotify_embed">
        <div id="embed-iframe"></div>
      </div>
      {/* <div id="embed-iframe"></div> */}
    </main>
  )
};

export default Queue;