import React, { useState } from 'react';
import { useHistory } from "react-router-dom";

import Front from './front';

const Index = (props) => {
  const history = useHistory();

  return (
    <Front
      onOkClick={() => {history.push("/app/profile")}}
      teste={props.teste}
    />
  )
}

export default Index;
