import React from "react";

import spotify from "../../assets/images/loginV2/spotify_logo.svg";

import folder from "../../assets/images/loginV2/folder.png";
import postIt from "../../assets/images/loginV2/post_it.png";
import pencil from "../../assets/images/loginV2/pencil.png";
import papers from "../../assets/images/loginV2/papers.png";
import pen from "../../assets/images/loginV2/pen.png";
import postItFor from "../../assets/images/loginV2/post_it_for.png";
import passport from "../../assets/images/loginV2/passport.png";

import keyboard from "../../assets/images/loginV2/keyboard.png";
import folder_2 from "../../assets/images/loginV2/folder_2.png";
import phone from "../../assets/images/loginV2/phone.png";

import jackfruit from "../../assets/images/loginV2/jackfruit.svg";

const LoginMinisterio = (props) => {
  return (
    <main id="login_with_spotify">
      <div className="bg_elements">
        <img
          id="folder"
          src={folder}
          alt="Pasta do Ministério do Namoro"
          title="Pasta do Ministério do Namoro"
        />
        
        <img
          id="postIt"
          src={postIt}
          alt="Post It"
          title="Post It"
        />

        <img
          id="pencil"
          src={pencil}
          alt="Lápis"
          title="Lápis"
        />

        <img
          id="papers"
          src={papers}
          alt="Papéis"
          title="Papéis"
        />

        <img
          id="pen"
          src={pen}
          alt="Caneta"
          title="Caneta"
        />

        <img
          id="keyboard"
          src={keyboard}
          alt="Teclado"
          title="Teclado"
          className="right"
        />

        <img
          id="folder_back"
          src={folder_2}
          alt="Pasta"
          title="Pasta"
          className="right"
        />

        <img
          id="phone"
          src={phone}
          alt="Smartphone"
          title="Smartphone"
          className="right"
        />
      </div>

      <div className="content">
        <img
          id="passport"
          src={passport}
          alt="Passaporte do Namoro"
          title="Passaporte do Namoro"
        />

        <img
          id="postItFor"
          src={postItFor}
          alt="Papéis"
          title="Papéis"
        />

        <p className="text center">
          Conecte-se com seu perfil<br className="mobile_only" /> no Spotify<br/>
          <span className="mobile_only_span"></span>e aguarde a sua vez<br className="mobile_only" /> na fila do Ministério do Namoro.
        </p>

        <a href={props.loginUrl} alt="Entrar com o Spotify" title="Entrar com o Spotify">
          <button
            type="button"
            className="btn_spotify"
            alt="Entrar com o Spotify"
            title="Entrar com o Spotify"
          >
            <img src={spotify} alt="Entrar com o Spotify" title="Entrar com o Spotify" />

            Entrar com o Spotify
          </button>
        </a>

        <span className="jackfruit text sm mt-23">
          Desenvolvido por

          <img
            id="jackfruit"
            className="ml-4px"
            src={jackfruit}
            alt="Jackfruit"
            title="Jackfruit"
          />
        </span>
      </div>
    </main>
  )
};

export default LoginMinisterio;