import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import profile from './profile/sagas';
import campaigns from './campaigns/sagas';
import states from './states/sagas';
import preferences from './preferences/sagas';
import likes from './likes/sagas';
import matches from './matches/sagas';

export default function* rootSaga() {
  return yield all([
    auth,
    profile,
    campaigns,
    states,
    preferences,
    likes,
    matches,
  ]);
}