import { select, all, call, takeLatest, put } from 'redux-saga/effects';
import { Profile } from '../../../services/api/profile';
import { isValidImage as isBase64 } from '../../../utils/checkImgUrl';
import history from '../../../services/history';

import { Types, Actions } from './actions';
import { Actions as AuthActions } from '../auth/actions';

import Jsona from 'jsona';
const dataFormatter = new Jsona();

function* loadProfile({ payload }) {
  const withIncluded = payload.included

  try {
    const response = yield call(Profile.load, withIncluded);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadProfileSuccess(data))
  } catch(err) {
    yield put(Actions.loadProfileFailed(err))
  }
}

function* loadMessagesResume() {

  try {
    const response = yield call(Profile.load);
    const { resume } = response.data.data.attributes

    yield put(Actions.loadMessagesResumeSuccess(resume))
  } catch(err) {
    // console.log(err)
  }
}

function* updateProfile({ payload }) {
  const { avatar, name, email, birthdate, genre, preferences, description, cityId } = payload

  const apiData = {
    user: {
      ...(isBase64(avatar) && avatar) && { "avatar": { "data": avatar } },
      name: name,
      email: email,
      birthdate: birthdate,
      genre: genre,
      preference: preferences,
      description: description,
      ...(cityId && cityId !== '- Selecionar -') && { "city_id": cityId },
      ...(cityId === null || cityId === '- Selecionar -') && { "city_id": null },
    }
  }

  try {
    yield call(Profile.update, apiData);

    yield put(Actions.updateProfileSuccess())

    const currentPath = window.location.pathname

    yield put(AuthActions.setProfileComplete(true))

    if(currentPath === '/app/signin') {
      history.push('/app/tutorial')
    } else {
      history.push('/app/profile')
    }
  } catch(err) {
    yield put(Actions.updateProfileFailed(err))
  }
}

function* deleteAccount() {
  const { currentCampaign } = yield select(state => state.auth);

  try {
    yield call(Profile.delete)
    yield put(Actions.deleteAccountSuccess())

    history.push(`/${currentCampaign}`)
  } catch(err) {
    yield put(Actions.deleteAccountFailed(err))
  }
}

export default all([
  takeLatest(Types.LOAD_PROFILE_REQUEST, loadProfile),
  takeLatest(Types.UPDATE_PROFILE_REQUEST, updateProfile),
  takeLatest(Types.DELETE_ACCOUNT_REQUEST, deleteAccount),
  takeLatest(Types.LOAD_MESSAGES_RESUME_REQUEST, loadMessagesResume),
])
