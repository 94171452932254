import React, { useState, useEffect } from 'react';
import Front from './front';

const Index = () => {

  return (
    <Front />
  )
}

export default Index;
