import produce from 'immer';
import { Types } from './actions';
import  { getStoredInfos } from '../../../utils/getStoredInfos';

const initialState = {
  matches: [],
  
  finishLoadMatches: false,

  deleteMatchOk: false,

  currentChat: {
    id: null,
    user: { name: null, image: null },
    chat: [],
    newMessage: true,
  },

  loadingMoreMessages: false,
}

export const matches = (state = initialState, action) => {  
  return produce(state, draft => {
    switch (action.type) {
      case Types.LOAD_MATCHES_REQUEST: {
        draft.finishLoadMatches = false
        break;
      }
      case Types.LOAD_MATCHES_SUCCESS: {
        const { data } = action.payload
        draft.matches = data

        if(data.length) {
          const stored = getStoredInfos()
          const user = data[0].users.find(item => item.id !== stored.profile.data.id)
          draft.currentChat.id = data[0].id
          draft.currentChat.user.name = user.name
        } else {
          draft.currentChat = initialState.currentChat
        }

        draft.finishLoadMatches = true
        break;
      }
      case Types.LOAD_MATCHES_FAILED: {
        draft.finishLoadMatches = false
        break;
      }
      case Types.LOAD_MESSAGES_REQUEST: {
        break;
      }
      case Types.LOAD_MESSAGES_SUCCESS: {
        const { messages } = action.payload
        
        const stored = getStoredInfos()
        const { profile } = stored

        function isMy(item) {
          return !(parseInt(profile.data.id) === parseInt(item.user_recipient.id)) 
        }

        const normalizedMsgs = messages.map(item => {
          return { my: isMy(item), message: item.content }
        })

        draft.currentChat.chat = normalizedMsgs.reverse()

        break;
      }
      case Types.LOAD_MORE_MESSAGES_REQUEST: {
        draft.loadingMoreMessages = true;
        break;
      }
      case Types.LOAD_MORE_MESSAGES_SUCCESS: {
        const { messages } = action.payload
        
        draft.loadingMoreMessages = false;
        
        const stored = getStoredInfos()
        const { profile } = stored
  
        function isMy(item) {
          return !(parseInt(profile.data.id) === parseInt(item.user_recipient.id)) 
        }
  
        const normalizedMsgs = messages.map(item => {
          return { my: isMy(item), message: item.content }
        })
        
        if(!normalizedMsgs.length) return;

        draft.currentChat.chat = [...normalizedMsgs.reverse(), ...draft.currentChat.chat]
        draft.currentChat.newMessage = false;

        break;
      }
      case Types.LOAD_MESSAGES_FAILED: {
        break;
      }
      case Types.SEND_MESSAGE_REQUEST: {
        break;
      }
      case Types.SEND_MESSAGE_SUCCESS: { 
        break;
      }
      case Types.SEND_MESSAGE_FAILED: {
        break;
      }
      case Types.RECEIVE_MESSAGE: {
        const { data } = action.payload

        const stored = getStoredInfos()

        const { profile } = stored
        const { content, match, user_recipient } = data.data.content

        if(parseInt(draft.currentChat.id) === parseInt(match.id)) {

          draft.currentChat.chat.push({
            my: (parseInt(profile.data.id) === parseInt(user_recipient.id)) ? false : true,
            message: content
          })

        }

        draft.currentChat.newMessage = true;
        break;
      }
      case Types.SWITCH_CURRENT_CHAT: {        
        const { roomId } = action.payload
        
        const selectedMatch = draft.matches.find(item => parseInt(item.id) === parseInt(roomId))
        
        const stored = getStoredInfos()

        if(selectedMatch) {
          const user = selectedMatch.users.find(item => item.id !== stored.profile.data.id)
          draft.currentChat.id = selectedMatch.id
          draft.currentChat.user.name = user.name
          draft.currentChat.user.image = user.avatar
        }
        break;
      }
      case Types.DELETE_MATCH_REQUEST: {
        draft.deleteMatchOk = false
        break;
      }
      case Types.DELETE_MATCH_SUCCESS: {
        draft.deleteMatchOk = true
        break;
      }
      case Types.DELETE_MATCH_FAILED: {
        draft.deleteMatchOk = true
        break;
      }
      case Types.UPDATE_UNREAD_MATCHS: {
        const { roomId, unread } = action.payload

        const matchIndex = draft.matches.findIndex(item => parseInt(item.id) === parseInt(roomId))

        draft.matches[matchIndex].messages.unread = unread;
        break;
      }
      default:
    }
  });
}
