import React, { Fragment, useState, useEffect } from "react";

import Front from "./front";

const Index = () => {
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    setFormData({
      image: undefined,
      name: "TESTE",
      email: "TESTE@teste.com",
      date: "01/01/1994",
      description: "ISSO É UM TESTE",
      genre: normalizeGenre("male"),
      city: 4485,
      state: "SC",
    });
  }, []);

  const normalizeGenre = (value) => {
    if (value === "female") return "m";
    if (value === "male") return "h";
    if (value === "non_binary") return "nb";
    if (value === "undefined") return "o";
  };

  return (
    <Front
      formData={formData}
      setFormData={setFormData}
      genreOptions={[
        { value: "-", label: "- selecionar -" },
        { value: "m", label: "Mulher" },
        { value: "h", label: "Homem" },
        { value: "nb", label: "Não-binário" },
        { value: "o", label: "Outro" },
      ]}
    />
  );
};

export default Index;
