export const Types = {
  LIKE_REQUEST: '@preferences/LIKE_REQUEST',
  LIKE_SUCCESS: '@preferences/LIKE_SUCCESS',

  DISLIKE_REQUEST: '@preferences/DISLIKE_REQUEST',
  DISLIKE_SUCCESS: '@preferences/DISLIKE_SUCCESS',

  RESET_MATCH: '@preferences/RESET_MATCH',
}

export const Actions = {

  likeRequest: (userId) => ({
    type: Types.LIKE_REQUEST,
    payload: { userId }
  }),
  
  likeSuccess: (match) => ({
    type: Types.LIKE_SUCCESS,
    payload: { match }
  }),

  dislikeRequest: (userId) => ({
    type: Types.DISLIKE_REQUEST,
    payload: { userId }
  }),
  
  dislikeSuccess: () => ({
    type: Types.DISLIKE_SUCCESS
  }),


  resetMatch: () => ({
    type: Types.RESET_MATCH
  })
}


