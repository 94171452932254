import React from 'react';
import {
  Page, Divider, Container, Button, Grid, Image, Logo,
  LoginLayout
} from '../../components';

function Front(props) {

  return (
    <Page name="404">
      <Container center>
        <Logo />
        <h6>Página não encontrada :-(</h6>
        <p>O link que você seguiu pode estar quebrado ou o endereço não foi digitado corretamente.</p>
      </Container>
    </Page>
  );
}

export default Front;
