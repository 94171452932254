import React, { useState, useEffect } from "react";
import {
  Page,
  Container,
  Image,
  Grid,
} from "../../components";
import { Button } from '../../components';
import {Link} from 'react-router-dom'

import { MusicList } from "../../components/MusicList";

import dateToAge from "../../utils/dateToAge";

import defaultImage from "../../assets/images/default.jpg";

function Front(props) {
  const [userData, setUserData] = useState(null);
  const [localData, setLocalData] = useState(props.formData);

  useEffect(() => {
      setUserData({
        ...localData,
        name: 'TESTE TESTE',
        age: 22,
        address: "Rio de Janeiro, RJ",
        description: 'Aqui vai uma breve descrição que a pessoa irá informar'
      });
    
  }, [localData]);

  return (
    <Page name="choose" loading={props.loadingForm}>
      {userData && (
        <Container center>
          <Grid>
            <Grid.cell className="cell-5_lg">
              <div className="ChooseMusic">
                <div className="choose-music-profile-info">
                  <Image
                    className="choose-music-profile-avatar"
                    src={userData.image ? userData.image : defaultImage}
                  />
                </div>
                <div className="choose-music-profile-info-wrapper">
                  <h3>{userData.name}, {userData.age}</h3>
                  <div className="address">{userData.address}</div>
                  <p className="choose-music-profile-description">
                    {userData.description}
                  </p>
                </div>
              </div>
            </Grid.cell>

            <Grid.cell  className="cell-1_lg"> </Grid.cell>

            <Grid.cell className="cell-6_lg">
              <MusicList />
            </Grid.cell>
          </Grid>
          <div className="choose-music-buttons">
            <Button className="back" link={Link} to="/app/profile">Voltar para perfil</Button>
            <Button className="save" >Publicar playlist</Button>
          </div>
        </Container>
      )}
    </Page> 
  );
}

export default Front;
