import { all, call, takeLatest, put } from 'redux-saga/effects';
import { Preferences } from '../../../services/api/prerefences';
import { LikeUris } from '../../../services/api/likeUris';

import { Types, Actions } from './actions';

import Jsona from 'jsona';
const dataFormatter = new Jsona();

function* loadPreferences({ payload }) {
  const { withAddress, page } = payload

  try {
    const response = yield call(Preferences.load, withAddress, page);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadUserPreferencesSuccess(data))
  } catch(err) {
    yield put(Actions.loadUserPreferencesFailed(err))
  }
}

function* loadMorePreferences({ payload }) {
  const { withAddress, page } = payload

  try {
    const response = yield call(Preferences.load, withAddress, page);
    const data = dataFormatter.deserialize(response.data)

    yield put(Actions.loadMoreUserPreferencesSuccess(data))
  } catch(err) {
    yield put(Actions.loadMoreUserPreferencesFailed(err))
  }
}

function* likeUri({ payload }) {
  const { id, currentUserIndex, kind } = payload

  try {
    yield call(LikeUris.like, { like_uri: { uri_id: id } });

    yield put(Actions.likeUriSuccess(id, currentUserIndex, kind))
  } catch(err) {
    yield put(Actions.likeUriFailed(err))
  }
}

function* dislikeUri({ payload }) {
  const { id, currentUserIndex, kind } = payload

  try {
    yield call(LikeUris.dislike, id);

    yield put(Actions.dislikeUriSuccess(id, currentUserIndex, kind))
  } catch(err) {
    yield put(Actions.dislikeUriFailed(err))
  }
}

export default all([
  takeLatest(Types.LOAD_USER_PREFERENCES_REQUEST, loadPreferences),
  takeLatest(Types.LOAD_MORE_USER_PREFERENCES_REQUEST, loadMorePreferences),
  takeLatest(Types.LIKE_URI_REQUEST, likeUri),
  takeLatest(Types.DISLIKE_URI_REQUEST, dislikeUri),
]);

